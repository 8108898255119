//// Colors
$body-bg: #131a24;
$body-color: #9ea4b4;

$light-blue: #cdd6dd;
$blue: #385b74;

$theme-colors: (
  "primary": #063151,
  "danger": #ff4136,
);


// Fonts
$font-family-sans-serif:      "Source Sans Pro", "Helvetica Neue", Helvetica, Arial, sans-serif;
$headings-font-family:        $font-family-sans-serif;
$headings-font-weight:        900;
