body {
  position: relative;
  font-weight: 400;
  background-position: 0;
  background-size: 100% auto;
  background-repeat: no-repeat;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

h1, h2, h3, h4, h5 {
  letter-spacing: -1px;
}

.app-longines-timing {
   position: relative;
   width:100%;
   min-height: 100vh;
   background-size: 100% auto;
   background-position: top center;
   background-repeat: no-repeat;
   background-image: url(../imgs/longines-timing-header-mobile.jpg);
   overflow: hidden;

   @include media-breakpoint-up(md) {
     background-image: url(../imgs/longines-timing-header.jpg);
   }

    &.alpine-skiing {
        background-image: url(../imgs/alpine-skiing-header-mobile.jpg);
        @include media-breakpoint-up(md) {
          background-image: url(../imgs/alpine-skiing-header.jpg);
        }
    }
    &.equestrian {
        background-image: url(../imgs/equestrian-header-mobile.jpg);
        @include media-breakpoint-up(md) {
          background-image: url(../imgs/equestrian-header.jpg);
        }

        .row{
          &:before,
          &:after{
            display: none;
          }
        }
    }
    &.gymnastics {
        background-image: url(../imgs/gymnastics-header-mobile.jpg);
        @include media-breakpoint-up(md) {
          background-image: url(../imgs/gymnastics-header.jpg);
        }
    }
    &.sport-history {
        background-image: url(../imgs/sport-history-header-mobile.jpg);
        @include media-breakpoint-up(md) {
          background-image: url(../imgs/sport-history-header.jpg);
        }
    }

    #loader{
      position: fixed;
      z-index: 9999;
      background-color: #131a24;
      top:0;
      bottom:0;
      left:0;
      right: 0;
      width: 100%;
      height: 100vh;

      .loader-inner {
        position: absolute;
        top: 50%;
        left: 50%;
        width: 75px;
        height: 16px;
        transform: translate(-50%,-50%);

        .loader-logo {
          mask-image: url(../imgs/hourglass.svg);
          mask-mode: alpha;
          mask-repeat: no-repeat;
          mask-size: 75px 16px;
          mask-position: center;
          width: 75px;
          height: 16px;

          .progress {
            height: 32px;
            background: transparent;
            border-radius: 0;
          }

          .progress-bar {
            height: 32px;
            border-radius: 0;
          	background-color: white;
            width: 100%;
            transition-property: all;
            transition-timing-function: ease-in-out;
            width: 0;
            animation: progressAnimationStrike 0.3s;
          }
        }
      }
      &.ie, &.edge{
          .loader-inner {
            background-image: url(../imgs/hourglass.png);
            background-size: contain;
            .loader-logo{
              .progress{
                display: none;
              }
              .progress-bar{
                display: none;
              }
            }
          }
      }
    }
}

@keyframes progressAnimationStrike {
     from { width: 0 }
     to   { width: 100% }
}

// fix for html data coming from Provider
.app-longines-timing {
  @media (max-width: 767px) {
    .hidden-xs{
      display: none;
    }
  }
  // SM
  @media (max-width: 991px) and (min-width: 768px) {
    .hidden-sm{
      display: none;
    }
  }
  @media (max-width: 1199px) and (min-width: 992px) {
    .hidden-md{
      display: none;
    }
  }
  @media (min-width: 1200px) {
    .hidden-lg{
      display: none;
    }
  }
}


a {
  color: theme-color('primary');
  transition: all .3s ease;

  &:hover,
  &:focus {
    text-decoration: none;
    color: darken(theme-color('primary'), 10%);
  }
}


// truncate
/* mixin for multiline */
@mixin multiLineEllipsis($lineHeight: 1.2em, $lineCount: 1, $bgColor: white){
  overflow: hidden;
  position: relative;
  line-height: $lineHeight;
  max-height: $lineHeight * $lineCount;
  text-align: left;
  margin-right: -1em;
  padding-right: 1em;
  &:before {
    content: '...';
    position: absolute;
    right: 0;
    bottom: 0;
  }
  &:after {
    content: '';
    position: absolute;
    right: 0;
    width: 1em;
    height: 1em;
    margin-top: 0.2em;
    background: $bgColor;
  }
}

.truncate-text {
  @include multiLineEllipsis($lineHeight: 1.2em, $lineCount: 2, $bgColor: theme-color('primary'));
}
