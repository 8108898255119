 main{
    .page{
        background-color: #eff1f3;
        .hero {
          min-height: 150px;
          padding: 30px 0;

          @include media-breakpoint-up(sm) {
            min-height: 250px;
          }
          @include media-breakpoint-up(md) {
            min-height: 350px;
          }

          h2 {
            position: relative;
            font-size: 16px;
            padding: 15px 0;
            font-weight: 500;
            letter-spacing: 1px;
            text-transform: uppercase;
            color: #fff;

            @include media-breakpoint-up(sm) {
              font-size: 24px;
              padding: 60px 0;
            }

            @include media-breakpoint-up(sm) {
              font-size: 24px;
              font-weight: 300;
              padding: 80px 0;
            }

            @include media-breakpoint-up(lg) {
              font-size: 54px;
              padding: 100px 0;
            }

            a {
              position: relative;
              color: #fff;
              padding-bottom: 15px;

              &:after {
                content:'';
                width: 0;
                height: 2px;
                background: #fff;
                position: absolute;
                bottom: 0;
                left: 50%;
                transform: translateX(-25px);
                transition: all .3s ease-out;
              }

              &.active,
              &:hover {
                &:after {
                  width: 50px;
                }
              }

            }
          }
        }

        .homepage & {
            background-color: transparent!important;
            background: transparent!important;
        }

      .page-inner {
        padding: 30px;
      }

      .filters {
        padding: 15px;
        text-transform: capitalize;
        background: white;
        text-align: center;

        @include media-breakpoint-up(sm) {
          padding: 30px;
        }

        .filter__control {
          border-radius: 0;

          &:focus {
            border-color: $blue;
          }
        }
      }
    }

     header {
        padding: 70px 0;

        h1 {
            color: #ffffff;
            text-transform: uppercase;
            font-size: 36px;
            font-weight: 300;
            letter-spacing: 2px;

            @include media-breakpoint-up(md) {
              font-size: 54px;
            }
        }
    }
    .event-listing {
        .page{
          min-height: 600px;
        }

        .no-results {
          padding: 100px 0;
          font-size: 36px;
          animation-delay: .5s;
        }

        .scheduled{
          margin-bottom: 0;
        }

        #mobile-nav {
          margin-bottom: 15px;

          @include media-breakpoint-up(lg) {
            display: none;
          }

          .filter__control {
            background: transparent;
            border-radius: 0;

            .filter__value-container {
              .filter__single-value,
              .filter__placeholder {
                color: #ffffff;
                letter-spacing: 1px;
                text-transform: uppercase;
              }
            }
            .filter__indicators {
              .filter__indicator-separator {
                background-color: transparent;
              }
            }
          }
          .filter__menu {
            border-radius: 0;
            border: 0 none;
            padding:0;
            margin: 0;
            box-shadow: 0px 15px 30px rgba(0,0,0,.15);
            z-index:1000!important;

            .filter__menu-list {
              .filter__option {
                &:hover {
                  background: #cdd6dd;
                  color: theme-color('primary');
                }
                &.filter__option--is-selected {
                  background: #385b74;
                  color: #ffffff!important;
                }
              }
            }
          }
        }
        #year-nav {
            padding: 30px 0;

            .nav {
              margin-bottom: 15px;
            }

            @include media-breakpoint-down(md) {
              display: none;
            }

            .nav-item-has-dropdown {
              display: flex;

              button {
                background: transparent;
                border: 0 none;
                outline: 0;
              }
            }

            a,
            button {
                text-align: center;
                color: rgba(#fff,.5);
                text-transform: uppercase;
                font-size: 30px;
                line-height: 30px;

                &:hover,
                &:focus,
                &.active {
                    color: #fff;
                }
                span {
                    margin:0;
                    padding: 0;
                    line-height: 16px;
                    display: block;
                    font-size: 16px;
                }
            }

        }
        #year-nav-dropdown{
          position: relative;
          z-index:1001!important;

          .filter__control {
            background: transparent;
            border-radius: 0;

            .filter__value-container {
              .filter__single-value,
              .filter__placeholder {
                color: #ffffff;
                letter-spacing: 1px;
                text-transform: uppercase;
              }
            }
            .filter__indicators {
              .filter__indicator-separator {
                background-color: transparent;
              }
            }
          }
          .filter__menu {
            border-radius: 0;
            border: 0 none;
            padding:0;
            margin: 0;
            box-shadow: 0px 15px 30px rgba(0,0,0,.15);
            z-index:1000!important;

            .filter__menu-list {
              .filter__option {
                &:hover {
                  background: #cdd6dd;
                  color: theme-color('primary');
                }
                &.filter__option--is-selected {
                  background: #385b74;
                  color: #ffffff!important;
                }
              }
            }
          }

        }

        .filters {
          position: relative;
          z-index: 999;

          .filters-toggle-wrapper {
            text-align: left;

            .btn {
              border-radius: 0;
              text-transform: uppercase;
              font-weight: 700;
              letter-spacing: 1px;
              background: transparent;
              color: #375a74;
              border: 0 none;
              outline: 0;
              box-shadow: none;
              display: inline-block;

              @include media-breakpoint-up(lg) {
                display: none;
              }

              &:focus {
                outline:0;
              }

              span {
                margin-left: 5px;
                line-height: 16px;
                vertical-align: middle;
              }
            }
            @include media-breakpoint-up(lg) {
              display: none;
            }

            .current-filters-state {
              display: block;
              text-align: center;

              .badge {
                margin: 0px 2px;
                // max-width: 80px;
                // white-space: nowrap;
                // overflow: hidden;
                // text-overflow: ellipsis;
              }
              .badge-primary {
                background: #cdd6dd;
                color: theme-color('primary');
              }
              .reset-current-filter {
                vertical-align: middle;
              }
            }

          }
          #filters-collapse {
            @include media-breakpoint-up(lg) {
              display: block;
            }
          }

          .reset-all-wrapper {
            display: inline-block;
            right: 0;
            float: right;

            @include media-breakpoint-up(lg) {
              margin-top: 15px;
              text-align: center;
            }

            .btn {
              border-radius: 0;
              text-transform: uppercase;
              font-weight: 700;
              letter-spacing: 1px;
              background: transparent;
              color: #9ea4b4;
              border: 0 none;
              outline: 0;
              box-shadow: none;

              &:focus {
                outline:0;
              }

              span {
                margin-left: 5px;
                line-height: 16px;
              }
            }
          }

          .filter__control {
            background: #385b74;
            border-radius: 0;
            border: 0 none;
            margin-bottom: 5px;
            width: 100%;
            text-align: center;

            @include media-breakpoint-up(lg) {
              margin-bottom: 0;
            }

            .filter__value-container {
              .filter__placeholder {
                color: #ffffff;
                letter-spacing: 1px;
                text-transform: uppercase;
                width: 80%;
                left: 50%;
                transform: translate(-50%,-50%);
              }
              .filter__single-value {
                color: #ffffff;
                text-align: center;
                transform: translate(-50%,-50%);
                margin: 0;
                left: 50%;
                top: 50%;
                width: 80%;
                max-width: 150px;

                @include media-breakpoint-up(md) {
                  max-width: 80%;
                }

                @include media-breakpoint-up(lg) {
                  max-width: 190px;
                }
              }
            }
            .filter__indicators {
              position: absolute;
              right: 0;
              color: #ffffff;

              @include media-breakpoint-down(lg) {
                position: absolute;
                right: 0;
              }
              .filter__indicator {
                color: #ffffff;
                padding-left: 5px;
                padding-right: 5px;
              }
              .filter__indicator-separator {
                background-color: transparent;
              }
            }
          }
          .filter__menu {
            border-radius: 0;
            border: 0 none;
            padding:0;
            margin: 0;
            box-shadow: 0px 15px 30px rgba(0,0,0,.15);
            text-transform: capitalize;
            z-index: 1000!important;

            .filter__menu-list {
              .filter__option {
                &:hover {
                  background: #cdd6dd;
                  color: theme-color('primary');
                }
                &.filter__option--is-selected {
                  background: #385b74;
                  color: #ffffff!important;
                }
              }
            }
          }

        }

      .event-header {
          padding: 30px;
      }

      .event {
          margin-bottom: 30px;

          &.event-no-url {
            .event-inner {
              opacity: 0.9;
              &:hover {
                box-shadow : none;
                cursor: default!important;
                .flag-overlay {
                  opacity: 0.05;
                  transform: rotate(10deg);
                }
              }
            }
          }

          @include media-breakpoint-down(sm) {
            padding-left: 0;
            padding-right: 0;
          }

          .event-inner {
            position: relative;
            overflow: hidden;
            background: #fff;
            transition: all .5s ease-out;

            @include media-breakpoint-up(md) {
              min-height: 370px;
            }

            .live-event-badge{
              position: absolute;
              display: inline-block;
              padding: 0 3px;
              top: 15px;
              left: 15px;
              background: #416278;
              color: #fff;
              z-index: 2;

              @include media-breakpoint-up(md) {
                left: auto;
                right: 15px;
              }


              &.current-event{
                background: #bd0606;
              }
            }

            &:hover {
              box-shadow: 0px 15px 30px rgba(0,0,0,.15);
              cursor: pointer;

              .flag-overlay {
                opacity: .12;
                transform: scale(1.1) rotate(10deg);
              }
            }

            header {
              position: relative;
              padding-top: 0;
              padding-bottom: 0;
              z-index: 1;

              .event-image-inner {
                  position: relative;
                  margin: 15px;
                  margin-right: 0;
                  margin-top: 40px;
                  @include media-breakpoint-up(sm) {
                    min-height: 150px;
                    margin-right: 15px;
                    margin-top: 15px;
                  }
                  .event-logo {
                    padding: 10px 0;
                    margin: 0;

                    @include media-breakpoint-up(sm) {
                      padding: 10px;
                    }
                      img {
                        @include media-breakpoint-up(sm) {
                          position: absolute;
                          top: 50%;
                          left: 50%;
                          transform: translate(-50%, -50%);
                          max-width: 150px;
                          max-height: 150px;
                          width: auto;
                          height: auto;
                        }
                      }
                    }
                  }
              }
              .event-content {
                position: relative;
                z-index: 1;

                .event-content-inner {
                  margin: 15px;
                  margin-left: 0;

                  @include media-breakpoint-up(sm) {
                    margin-left: 15px;
                  }

                  h3 {
                      font-size: 20px;
                      font-weight: 400;
                      height: 76px;
                      margin-bottom: 2px;
                  }

                  .event-details {
                    margin: 0;
                    min-height: 77px;
                    li {
                      vertical-align: middle;

                      .icon {
                        display: inline-block;
                        vertical-align: middle;
                        margin-right: 5px;
                      }
                    }
                  }

                  footer {
                    display: none;
                    align-items: flex-end;

                    ul {
                      justify-content: flex-end;
                      @include media-breakpoint-up(sm) {
                        justify-content: center;
                      }
                    }
                      a {
                          color: $body-color;
                          font-size: 36px;
                          &:hover,
                          &:focus {
                              color: theme-color('primary');
                          }
                      }
                  }
                }
              }
              .flag-overlay {
                  position: absolute;
                  bottom: 0;
                  right: 0;
                  left: auto;
                  transform: rotate(10deg);
                  font-size: 120px;
                  opacity: 0.05;
                  line-height: 0;
                  z-index: 0;
                  transition: all 1s ease-out;

                  @include media-breakpoint-up(sm) {
                    font-size: 220px;
                    right: auto;
                    left: auto;
                    bottom: -50px;
                  }
              }
          }

          &.event-cancelled {
              .event-inner {
                  opacity: 0.4;

                  .event-cancelled-info {
                      position: absolute;
                      top: 50%;
                      transform: translateY(-50%);
                      width: 100%;
                      z-index: 99;
                      text-align: center;

                      p {
                          display: inline-block;
                          transform: rotate(-6deg);
                          background: #444;
                          padding: 5px;
                          color: #fff;
                          font-size: 15px;
                          letter-spacing: 1px;

                          @include media-breakpoint-up(sm) {
                            font-size: 20px;
                            padding: 15px 30px;
                          }
                      }
                  }
              }
          }

      }
    }
    .scheduled {
      position: relative;
      background-color: theme-color('primary');
      color: #eff1f3;
      margin-bottom: 30px;

      &.current {
        .scheduled-title {
          background: #bd0606;
        }
      }

      .scheduled-listing {
        position: relative;
        height: 270px;

        @include media-breakpoint-up(sm) {
          height: 300px;
        }
      }

      .slick-dots {
        position: absolute;
        bottom: 10px;

        li {
          transform-origin: center;

          button {
            transition: all .3s ease;
            transform-origin: center;

            &:before {
              content:'';
              width: 20px;
              height: 3px;
              background: #ffffff;
              border-radius: 2px;
              transition: all .3s ease-out;
              transform-origin: center;
            }
          }

          &.slick-active {
            width: 30px;
            button {
              &:before {
                width: 30px;
              }
            }
          }

        }
      }

      .slick-arrow {
        display: none;
        visibility: hidden;
      }

      .scheduled-title {
        position: absolute;
        top: 30px;
        left: 30px;
        padding: 2px;
        background: #416278;
        font-size: 24px;
        line-height: 24px;
        text-transform: uppercase;
        z-index: 3;
      }

      .event-scheduled-item {
        position: relative;
        background-size: cover;
        background-position: center;
        background-repeat: no-repeat;
        background-image: url(../imgs/alpine-skiing-event.jpg);

        &.alpine-skiing {
          background-image: url(../imgs/alpine-skiing-event.jpg);
        }
        &.equestrian {
          background-image: url(../imgs/equestrian-event.jpg);
        }
        &.gymnastics {
          background-image: url(../imgs/gymnastics-event.jpg);
        }

        &:after {
          content: '';
          position: absolute;
          width: 100%;
          height: 100%;
          top: 0;
          left: 0;
          background: rgba(theme-color('primary'),0.9);
        }

        .event-scheduled-inner {
          position:relative;
          padding: 30px;
          min-height: 200px;
          width:100%;
          height:100%;
          display: block;
          z-index: 1;

          @include media-breakpoint-up(md) {
            min-height: 300px;
          }

          .link-to-event {
            position: absolute;
            top: 30px;
            right: 30px;
            border: 1px solid #fff;
            padding: 5px 10px;
            text-transform: uppercase;
            letter-spacing: 1px;
            color: #ffffff;
            transition: all .3s ease;

            &:hover {
              background: #ffffff;
              color: theme-color('primary');
            }
          }

          .event-info {
            width: 100%;
            padding-top: 40px;
            @include media-breakpoint-up(lg) {
              // width: 70%;
              // float:left;
              padding-right: 15px;
            }

            h4 {
              font-size: 16px;
              text-transform: uppercase;
              letter-spacing: 2px;
              font-weight: 400;
            }

            h3 {
              font-weight: 400;
              height: 70px;
              a{
                color: inherit;
              }
            }

            .event-details {
              li {
                vertical-align: middle;

                .icon {
                  display: inline-block;
                  vertical-align: middle;
                  margin-right: 5px;
                }
              }
            }
          }
        }
        &:after {
          content:'';
          clear: both;
        }
      }
    }

     .longines-sport-history {
          position: relative;
          background-color: theme-color('primary');
          color: #eff1f3;
          padding: 30px;
          margin-bottom: 30px;
          background-size: cover;
          background-position: center;
          background-repeat: no-repeat;
          background-image: url(../imgs/longines-sport-history-bg.jpg);

          &:after {
            content: '';
            position: absolute;
            width: 100%;
            height: 100%;
            top: 0;
            left: 0;
            background: rgba(theme-color('primary'),0.9);
          }


          a {
            color: #ffffff;
          }

          .longines-sport-history-inner {
            position: relative;
            z-index: 1;

            .box-content {

              @include media-breakpoint-down(md) {
                margin-bottom: 60px;
              }

              h3 {
                font-weight: 400;
              }
            }
            .box-watch {
              text-align: center;
            }
          }
     }
  }

// Sport History page
  .sport-history {
    .txt-wrap {
      padding: 30px;
      @include media-breakpoint-up(sm) {
        padding: 80px;
      }
    }
  }

  // off canvas
#offcanvas {
	position: fixed;
	z-index: 1001;
	width: 100%;
	background: theme-color('primary');
  color: #ffffff;
	top: 0;
	right: 0;
	top: 0;
	bottom: 0;
	padding: 0;
	overflow-y:hidden;
  transform: translateX(-100%);
  transition: all .3s ease-out;


	.offcanvas & {
      transform: translateX(0);
	}

  .mobile-menu {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    width: 100%;
    text-align: center;

    .clock-wrapper {
      transform-origin: top center;
      margin-left: -15px;
      transform: scale(0.5);
      max-height: 150px;
    }

    a {
      display: block;
      color: #fff;
      text-align: center;
      text-transform: uppercase;
      letter-spacing: 2px;
      padding: 5px 10px;
      font-size: 24px;
      font-weight: 500;

      @include media-breakpoint-up(sm) {
        padding: 8px 15px;
      }

      &.active {
        background-color: transparent;
      }
    }
  }

  .footer {
    position: absolute;
    bottom: 0;
    width: 100%;
    text-align: center;

    .footer-nav {
      justify-content: center;
    }
  }

	ul {
		padding: 0;
		margin: 0;
        position: relative;

		li {
			padding: 0;
			margin: 0;
			list-style: none;
            margin-bottom: 8px;

			> ul {
				padding-left: 20px;
				display: none;
			}
			&.offcanvas-has-dropdown {
				> a {
					display: block;
					position: relative;
					&:after {
						position: absolute;
						right: 0px;
						font-family: "Ionicons";
						content: "\f123";
						font-size: 20px;
						color: rgba(255,255,255,.2);
                        transition: all .5s ease;
					}
				}
				&.active {
					a {
						&:after {
							transform: rotate(-180deg);
						}
					}
				}
			}
		}
	}
}
